import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import pesiImage from "../images/pesi.jpeg";

import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormRadio,
  Alert,
  FormCheckbox,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";

const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.getFormFields = this.getFormFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.RenderFields = this.RenderFields.bind(this);
    this.firstSend = this.firstSend.bind(this);
    this.nextData = this.nextData.bind(this);
    this.prevData = this.prevData.bind(this);
    this.BottomButton = this.BottomButton.bind(this);
    this.CustomRadio = this.CustomRadio.bind(this);
    this.CustomCheckBox = this.CustomCheckBox.bind(this);
    this.bmiCal = this.bmiCal.bind(this);
    this.pesiCalk = this.pesiCalk.bind(this);
    this.redirectState = this.redirectState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.ClearArr = this.ClearArr.bind(this);
    this.alert = this.alert.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.uploadLoading = this.uploadLoading.bind(this);
    this.state = {
      formFields: [],
      fieldValue: {},
      checkBoxValue: [],
      sliceArray: [],
      start: 0,
      end: 1,
      id: null,
      open: false,
      selectedFile: false,
      redirect: false,
      isLoading: false
    };
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(
        `${URL.BASE_URL}/fields/getfollowfield/${this.props.match.params.id}`,
        config
      )
      .catch(error => {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      this.setState({
        formFields: response.data.data,

        fieldValue: {
          patient_id_477: response.data.patientData.fields.patient_id_824,
          patient_name_388: response.data.patientData.fields.patient_name_166
        }
      });
      //console.log(response.data.data);

      let fields = [];
      let sliceNo = [];

      fields = response.data.data;

      fields.forEach((item, key) => {
        // console.log(key, item.fieldType);
        if (item.fieldType === "title") {
          sliceNo.push(key);
        }
      });
      sliceNo.push(fields.length);

      this.setState({
        sliceArray: sliceNo
      });

      //------
    }
  }

  handleChange(e) {
    var merged;
    let newItem;
    let oldItem = this.state.fieldValue;
    //this.pesiCalk(e.target.value, e.target.name)
    if (e.target.name === "height_m_825") {
      this.bmiCal(e.target.value, 0);
    }
    if (e.target.name === "weight_kg_328") {
      this.bmiCal(0, e.target.value);
    }

    newItem = { [e.target.name]: e.target.value };
    merged = { ...oldItem, ...newItem };
    this.setState({
      fieldValue: merged
    });
  }

  async fileUpload(e) {
    let name = e.target.name;
    this.setState({
      selectedFile: true
    });
    const data = new FormData();
    data.append("image", e.target.files[0]);

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let response = await axios
      .post(`${URL.BASE_URL}/forms/files`, data, config)
      .catch(function(error) {
        console.log(error);
      });

    if (!response.data.error && response.data.statusCode === 200) {
      this.setState({
        [name]: response.data.filename,
        selectedFile: false
      });
    }
    //console.log(name);
  }

  handleCheck(e) {
    let item = e.target.name;
    let value = e.target.value;
    let newItem = [];

    if (e.target.checked) {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.push(value);
    } else {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.pop(value);
    }

    this.setState({
      [item]: newItem
    });

    console.log(this.state);
  }

  alert() {
    if (this.state.validation) {
      //window.scrollTo(0, 0);
      let msg = "";
      if (
        !this.state.fieldValue.patient_name_330 &&
        this.state.start + 1 === 1
      ) {
        msg = "Name Can Not Be Empty";
      } else if (
        !this.state.fieldValue.ip_no_635 &&
        this.state.start + 1 === 1
      ) {
        msg = "IP Number Can Not Be Empty";
      } else if (
        !this.state.fieldValue.patient_id_439 &&
        this.state.start + 1 === 1
      ) {
        msg = "Patient ID No Can Not Be Empty";
      } else if (
        !this.state.fieldValue.patient_age_131 &&
        this.state.start + 1 === 1
      ) {
        msg = "Age Can Not Be Empty";
      } else if (
        !this.state.fieldValue.mobile_number_phone_number_1_358 &&
        this.state.start + 1 === 1
      ) {
        msg = "Mobile No Can Not Be Empty";
      } else if (
        !this.state.fieldValue.address_671 &&
        this.state.start + 1 === 1
      ) {
        msg = "Address Can Not Be Empty";
      } else if (
        !this.state.fieldValue.date_of_onset_of_symptoms_122 &&
        this.state.start + 1 === 1
      ) {
        msg = "date of onset of symptoms Can Not Be Empty";
      } else if (
        !this.state.fieldValue.malignancy_451 &&
        this.state.start + 1 === 2
      ) {
        msg = "Malignancy Can Not Be Empty";
      } else if (
        !this.state.fieldValue.hrt_ocp_use_209 &&
        this.state.start + 1 === 2
      ) {
        msg = "HORMONE REPLACEMENT THERAPY/OCP USE";
      } else if (
        !this.state.fieldValue.pregnancy_postpartum_468 &&
        this.state.start + 1 === 2
      ) {
        msg = "Pregnancy Can Not Be Empty";
      } else if (
        !this.state.fieldValue.postpartum_291 &&
        this.state.start + 1 === 2
      ) {
        msg = "Postpartum Can Not Be Empty";
      } else if (
        !this.state.fieldValue
          .recent_8_weeks_long_duration_of_travel_4_hrs_261 &&
        this.state.start + 1 === 2
      ) {
        msg = "Travel History Can Not Be Empty";
      } else if (
        !this.state.fieldValue.recent_surgery_immobilisation_3_months_383 &&
        this.state.start + 1 === 2
      ) {
        msg = "Recent Surgery Can Not Be Empty";
      } else if (
        !this.state.fieldValue.recent_immobilaztion_3_month_709 &&
        this.state.start + 1 === 2
      ) {
        msg = "Recent immobilaztion Can Not Be Empty";
      } else if (
        !this.state.fieldValue.was_the_patient_on_primary_dvt_prophylaxis_345 &&
        this.state.start + 1 === 2
      ) {
        msg =
          "Was the patient on DVT treatment prior to detection of pulmonary embolism? Can Not Be Empty";
      } else if (
        !this.state.fieldValue
          .was_the_patient_on_dvt_treatment_prior_to_detection_of_pulmonary_embolism_989 &&
        this.state.start + 1 === 2
      ) {
        msg = "Was the patient on dvt treatment Can Not Be Empty";
      } else if (
        (!this.state.fieldValue.dyspnoea_661 ||
          !this.state.fieldValue.cough_347 ||
          !this.state.fieldValue.syncope_453 ||
          !this.state.fieldValue.syncope_453 ||
          !this.state.fieldValue.pre_syncope_524 ||
          !this.state.fieldValue.palpitation_795 ||
          !this.state.fieldValue.chest_pain_159 ||
          !this.state.fieldValue.hemoptysis_304 ||
          !this.state.fieldValue.unilateral_limb_swelling_pain_681 ||
          !this.state.fieldValue.mention_first_symptom_248) &&
        this.state.start + 1 === 3
      ) {
        msg = "All Fields in this Page are Mandatory";
      } else if (
        !this.state.fieldValue.pulse_rate_min_697 &&
        this.state.start + 1 === 4
      ) {
        msg = "Pulse Rate Can Not Be Empty";
      } else if (
        !this.state.fieldValue.respiratory_rate_min_631 &&
        this.state.start + 1 === 4
      ) {
        msg = "Respiratory rate Can Not Be Empty";
      } else if (
        !this.state.fieldValue.systolic_bp_mm_hg_510 &&
        this.state.start + 1 === 4
      ) {
        msg = "Systolic BP Can Not Be Empty";
      } else if (
        !this.state.fieldValue.diastolic_bp_mm_hg_522 &&
        this.state.start + 1 === 4
      ) {
        msg = "Diastolic BP Can Not Be Empty";
      } else if (
        !this.state.fieldValue
          .spo2_mention_room_air_or_o2_supplementation_191 &&
        this.state.start + 1 === 4
      ) {
        msg = "SPO2 Can Not Be Empty";
      } else if (
        (!this.state.fieldValue.parenteral_antocoagulation_given_812 ||
          !this.state.fieldValue.intial_agent_929 ||
          !this.state.fieldValue.whether_oac_used_663 ||
          !this.state.fieldValue.need_for_mechanical_ventilation_215 ||
          !this.state.fieldValue.stroke_during_hospital_stay_146 ||
          !this.state.fieldValue.any_bleeding_during_hospital_stay_137 ||
          !this.state.fieldValue.if_bleeding_present_barc_type_313 ||
          !this.state.fieldValue.readmission_841 ||
          !this.state.fieldValue.recurrence_644 ||
          !this.state.fieldValue.whether_malignancy_detected_on_work_up_897) &&
        this.state.start + 1 === 9
      ) {
        msg = "All Fields in this Page are Mandatory";
      } else if (
        !this.state.fieldValue.inhospital_death_971 &&
        this.state.start + 1 === 10
      ) {
        msg = "In Hospital Death Can Not Be Empty";
      } else if (
        !this.state.fieldValue
          .time_from_hospital_admission_to_death_hours_432 &&
        this.state.start + 1 === 10
      ) {
        msg =
          "Time from hospital admission to death ( hours ) Can Not Be Empty";
      } else if (
        !this.state.fieldValue.whether_patient_discharged_from_hospital_297 &&
        this.state.start + 1 === 11
      ) {
        msg = "whether patient discharged from hospital ? Can Not Be Empty";
      } else if (
        !this.state.fieldValue
          .total_days_of_hospital_stay_before_discharge_993 &&
        this.state.start + 1 === 11
      ) {
        msg = "Total days of hospital stay before discharge Can Not Be Empty";
      } else if (
        !this.state.fieldValue.anti_coagulation_agent_on_discharge_124 &&
        this.state.start + 1 === 11
      ) {
        msg = "Anti coagulation agent on discharge Can Not Be Empty";
      }
      if (msg === "") {
        return <div></div>;
      }
      return (
        <>
          <Alert theme="danger" className="mb-0">
            <i className="fa fa-info mx-2"></i> Error {msg}
          </Alert>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  nextData() {
    if (
      !this.state.fieldValue.patient_name_330 ||
      !this.state.fieldValue.ip_no_635
    ) {
      this.setState({
        validation: true
      });
    } else {
      this.setState({
        validation: false
      });
      let oldStart = this.state.start;
      let oldEnd = this.state.end;
      this.setState({
        start: oldStart + 1,
        end: oldEnd + 1
      });
      window.scrollTo(0, 0);
      console.log(this.state);
      this.firstSend();
    }
  }
  prevData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart - 1,
      end: oldEnd - 1
    });
    window.scrollTo(0, 0);
  }
  pagination(pageNo) {
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    this.setState({
      validation: false
    });

    this.setState({
      start: pageNo - 1,
      end: pageNo
    });
    window.scrollTo(0, 0);
    console.log(this.state);
    this.firstSend();
  }

  async firstSend(e) {
    //e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let stateData = Object.assign({}, this.state);
    delete stateData.formFields;
    delete stateData.fieldValue;
    delete stateData.checkBoxValue;
    delete stateData.start;
    delete stateData.end;
    delete stateData.sliceArray;
    delete stateData.id;
    delete stateData.isCompleted;
    delete stateData.selectedFile;
    delete stateData.isLoading;
    delete stateData.redirect;
    delete stateData.validation;
    delete stateData.id;
    let dataToSend = { ...this.state.fieldValue, ...stateData };

    let finalData = {
      fieldValues: dataToSend,
      patientId: this.props.match.params.id,
      followUpNo: this.props.match.params.no
    };

    if (this.state.id) {
      finalData.id = this.state.id;
    }

    console.log(finalData);

    console.log("Sening");
    let response;
    if (this.state.start + 1 === this.state.sliceArray.length - 1) {
      finalData.isCompleted = true;
      this.setState({
        isCompleted: true
      });
    }
    if (this.state.id == null && this.state.start === 0) {
      response = await axios
        .post(`${URL.BASE_URL}/forms/addfollowupdata`, finalData, config)
        .catch(function(error) {
          console.log(error);
        });
      if (response.data.data) {
        this.setState({
          id: response.data.data._id
        });
      }
    } else if (this.state.id != null) {
      dataToSend.id = this.state.id;
      response = await axios
        .patch(`${URL.BASE_URL}/forms/addfollowupdata`, finalData, config)
        .catch(function(error) {
          console.log(error);
        });
    }
  }

  redirectState() {
    this.firstSend();
    this.setState({
      redirect: true
    });
  }

  BottomButton() {
    let start = this.state.start;
    let end = this.state.end;
    // console.log(this.state.sliceArray, start, end);
    let arrayLength = this.state.sliceArray.length;
    if (start === 0) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              {/*
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              */}
              {this.state.sliceArray.map((item, index) => {
                if (
                  index > 0 &&
                  index > this.state.end - 5 &&
                  index < this.state.end + 5
                )
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => this.pagination(this.state.start + 2)}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start === arrayLength - 2 && end === arrayLength - 1) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              {/*
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              */}
              {this.state.sliceArray.map((item, index) => {
                if (
                  index > 0 &&
                  index > this.state.end - 5 &&
                  index < this.state.end + 5
                )
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.redirectState}
                >
                  Submit
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start > 0 && end < arrayLength) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (
                  index > 0 &&
                  index > this.state.end - 5 &&
                  index < this.state.end + 5
                )
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={() => this.pagination(this.state.start + 2)}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div>" "</div>;
    }
  }

  CustomRadio({ name, item }) {
    if (item === this.state.fieldValue[name]) {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            defaultChecked
            style={{
              marginTop: "2px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          <div
            style={{
              marginLeft: "15px",
              fontSize: "16px"
            }}
          >
            {item}
          </div>
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          <div
            style={{
              marginLeft: "15px",
              fontSize: "16px"
            }}
          >
            {item}
          </div>
        </>
      );
    }
  }

  CustomCheckBox({ name, item }) {
    let arr = [];
    if (this.state[name]) {
      arr = this.state[name];
    } else if (this.state.fieldValue[name]) {
      arr = this.state.fieldValue[name];
    }
    //console.log(name, item);
    let isChecked = arr.includes(item);
    return (
      <>
        <Col md="3">
          <input
            type="checkbox"
            id="checkbox1"
            defaultChecked={isChecked}
            name={name}
            value={item}
            onChange={this.handleCheck}
          />
          &nbsp; &nbsp; &nbsp;
          {item}
        </Col>
      </>
    );
  }

  ClearArr({ name }) {
    console.log(name);

    this.setState({
      [name]: []
    });
    console.log(this.state[name]);
  }

  uploadLoading({ slug }) {
    var fileName;
    fileName = this.state[slug];
    if (this.state.selectedFile) {
      return (
        <>
          <Row
            style={{
              marginTop: "25px"
            }}
          >
            <Col md="1">
              <ReactLoading
                type={"spin"}
                color={"#5af"}
                height={"25px"}
                width={"25px"}
              />
            </Col>
            <Col md="3">
              <strong>Uploading...</strong>
            </Col>
          </Row>
        </>
      );
    } else {
      if (fileName) {
        return (
          <div>
            <a href={`${URL.BASE_URL}/${fileName}`} target="_blank">
              View File
            </a>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }

  bmiCal(h, w) {
    let height = Number(this.state.fieldValue["height_m_825"]);
    let weight = 0; // Number(this.state.fieldValue['weight_kg_328'])
    if (h !== 0) {
      height = h;
    }
    if (w !== 0) {
      weight = w;
    }

    let bmi = (Number(weight) / (Number(height) * Number(height))).toFixed(2);
    console.log(bmi, height, weight, this.state.bmi_w_kgm2_996);
    this.setState({
      bmi_w_kgm2_996: bmi
    });
  }

  pesiCalk() {
    let pesi_score = Number(this.state.fieldValue["age_220"]);
    if (this.state.fieldValue.patient_age_131) {
      pesi_score = Number(this.state.fieldValue.patient_age_131);
    }
    //let pesi_score = Number(this.state.fieldValue.patient_age_131); // Number(this.state.pesi_final)
    if (this.state.fieldValue["male_gender_10_points_814"] === "Yes") {
      pesi_score += 10;
    }
    if (this.state.fieldValue["cancer_history_30_points_288"] === "Yes") {
      pesi_score += 30;
    }
    if (this.state.fieldValue["heart_failure_10_points_735"] === "Yes") {
      pesi_score += 10;
    }
    if (this.state.fieldValue["chronic_lung_disease_10_points_657"] === "Yes") {
      pesi_score += 10;
    }
    if (
      this.state.fieldValue["pulse_110_beats_per_min_20_points_432"] === "Yes"
    ) {
      pesi_score += 20;
    }
    if (this.state.fieldValue["sbp_100_mm_hg_30_points_139"] === "Yes") {
      pesi_score += 30;
    }
    if (
      this.state.fieldValue["respiratory_rate_30_per_min_20_points_775"] ===
      "Yes"
    ) {
      pesi_score += 20;
    }
    if (
      this.state.fieldValue["temperature_36_degree_celsius_20_points_630"] ===
      "Yes"
    ) {
      pesi_score += 20;
    }
    if (
      this.state.fieldValue[
        "disorientation_lethargystupor_coma_60_points_676"
      ] === "Yes"
    ) {
      pesi_score += 60;
    }
    if (this.state.fieldValue["sao2_90_20_points_558"] === "Yes") {
      pesi_score += 20;
    }

    let pesiClass;
    if (pesi_score < 66) {
      pesiClass = "CLASS 1 < 66 Points";
    } else if (pesi_score < 86) {
      pesiClass = "CLASS 2 < 66 - 85 Points";
    } else if (pesi_score < 106) {
      pesiClass = "CLASS 3 < 86 - 105 Points";
    } else if (pesi_score < 126) {
      pesiClass = "CLASS 4 < 106 - 125 Points";
    } else if (pesi_score > 125) {
      pesiClass = "CLASS 5 > 125 Points";
    }

    this.setState({
      pesi_final: pesi_score,
      pesi_cls: pesiClass
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  RenderFields() {
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }

    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    return (
      <div>
        {fields.slice(start, end).map(field => {
          var parent_name;
          if (field.parent) {
            parent_name = this.state.fieldValue[
              fields[field.parent + 1].fieldSlug
            ];
          }

          //  console.log("pos ------>", field.parent);
          //  console.log(" value ------>", fields[field.parent]);
          //  console.log(" parent ------>", fields[field.parent - 1]);
          //   console.log("------------------------");

          //for Indication for readmission

          if (
            field.fieldSlug === "date_of_4readmission_319" &&
            this.state.fieldValue["readmission_219"] === "Yes"
          ) {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          Indication for readmission
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name="indication_for_readmission_980"
                        onChange={this.handleChange}
                        value={
                          this.state.fieldValue[
                            "indication_for_readmission_980"
                          ]
                        }
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />

                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      min={this.state.fieldValue["date_of_admission_180"]}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              </>
            );
          }

          if (field.fieldSlug === "indication_for_readmission_980") {
            return <div> </div>;
          } // To hide Indication for readmission

          if (field.parent && parent_name !== "Yes") {
            return <div> </div>;
          } else if (
            field.fieldSlug === "thrombus_location_371" &&
            this.state.fieldValue.site_of_obstruction_879 === "Unilateral Left"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (
            field.fieldSlug === "thrombus_location_371" &&
            this.state.fieldValue.site_of_obstruction_879 === "Unilateral Right"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{fields[97].fieldName} : </Col>
                  {fields[97].checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={fields[97].fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (
            field.fieldSlug === "thrombus_location_371" &&
            (this.state.fieldValue.site_of_obstruction_879 === "Bilateral" ||
              this.state.fieldValue.site_of_obstruction_879 ===
                "Saddle Embolism")
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{fields[98].fieldName} : </Col>
                  {fields[98].checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={fields[98].fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (
            field.fieldSlug === "thrombus_location_371" ||
            field.fieldSlug === "thrombus_location_456" ||
            field.fieldSlug === "thrombus_location_198"
          ) {
            return <div></div>;
          } else if (field.fieldType === "text") {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "number") {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "radio") {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          } else if (field.fieldType === "check_box") {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (field.fieldType === "dropdown") {
            if (field.fieldSlug === "class_762") {
              return (
                <>
                  <Col md="3">
                    <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                    <FormSelect
                      id="feInputState"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                    >
                      <option>{this.state.pesi_cls}</option>
                      {field.checkBoxEnum.map(item => {
                        return (
                          <>
                            <option>{item}</option>
                          </>
                        );
                      })}
                    </FormSelect>
                  </Col>
                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col md="3">
                    <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                    <FormSelect
                      id="feInputState"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                    >
                      <option>{this.state.fieldValue[field.fieldSlug]}</option>
                      {/* <option>Select One</option>*/}
                      {field.checkBoxEnum.map(item => {
                        return (
                          <>
                            <option>{item}</option>
                          </>
                        );
                      })}
                    </FormSelect>
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "date") {
            if (field.fieldSlug === "date_of_discharge_244") {
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      min={this.state.fieldValue["date_of_admission_180"]}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldSlug === "date_of_death_968") {
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      min={this.state.fieldValue["date_of_admission_180"]}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="date"
                      id="date"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                      style={{
                        padding: "5px 5px",
                        margin: "8px 0",
                        "box-sizing": "border-box",
                        color: "#ccc",
                        border: "1px solid #ccc",
                        "border-radius": "4px"
                      }}
                      value={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "title") {
            return (
              <>
                <Col md="10">
                  <h2> {field.fieldName} </h2>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "sub_title") {
            return (
              <>
                <Col md="10">
                  <h5>
                    {" "}
                    <strong> {field.fieldName} </strong>{" "}
                  </h5>
                  <hr />
                </Col>
              </>
            );
          } else if (
            field.fieldType === "image" ||
            field.fieldType === "file"
          ) {
            return (
              <>
                {field.fieldName} &nbsp;&nbsp; : &nbsp;&nbsp;
                <Col md="5" sm="12">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile2"
                    name={field.fieldSlug}
                    onChange={this.fileUpload}
                  />
                  <label className="custom-file-label" htmlFor="customFile2">
                    {this.state[field.fieldSlug]}
                  </label>
                  <this.uploadLoading slug={field.fieldSlug} />
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "document") {
            if (field.fieldSlug === "pesi_score_188") {
              return (
                <div
                  style={{
                    margin: "50px"
                  }}
                >
                  <Row>
                    <img
                      src={pesiImage}
                      alt="Logo"
                      width="720px"
                      height="540px"
                    />
                  </Row>
                  <Row>
                    <div
                      style={{
                        margin: "20px",
                        color: "red",
                        fontSize: "20px"
                      }}
                    >
                      <br />
                      <strong>All Fields are Mandatory !</strong>
                    </div>
                  </Row>
                </div>
              );
            } else {
              return (
                <Button
                  outline
                  theme="dark"
                  className="mb-2 mr-1"
                  onClick={this.toggle}
                >
                  For BARC Bleeding Criteria
                </Button>
              );
            }
          }
          //return <li key={field.fieldSlug}>{field.fieldType}</li>;
        })}
        <this.BottomButton />
        <br />
        <strong>
          Steps : {this.state.start + 1} / {this.state.sliceArray.length - 1}
        </strong>

        <div>
          <Modal open={this.state.open} size="lg" toggle={this.toggle}>
            <ModalHeader>
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                Close
              </Button>
            </ModalHeader>
            <ModalBody>
              <img
                src="http://csisc2020.org/BARC.png"
                alt="critiria"
                width="140%"
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <this.alert />
        <Row noGutters className="page-header py-4">
          {/*   <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
          */}
        </Row>
        <Row>
          <Col md="12">
            <this.RenderFields />
          </Col>
        </Row>
        <this.alert />
      </Container>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashbord" />;
    }
    if (this.state.sliceArray.length < 1) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return this.AddNewPost();
    }
  }
}
