import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import DashbordCard from "../components/clean/dashbordCard";

import PageTitle from "./../components/common/PageTitle";

const BlogOverview = ({ smallStats }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle
        title=" Overview"
        subtitle="Dashboard"
        className="text-sm-left mb-3"
      />
    </Row>
    <DashbordCard />

    <Row className="mt-5 justify-content-md-center">
      <Col lg="10">
        <div
          style={{
            textAlign: "justify",
            textJustify: "inter-word",
            backgroundColor: "#fff",
            padding: "50px",
            marginBottom: "100px",
            borderRadius: "20px",
            color: "black",
            fontSize: "16px",
            lineHeight: "30px",
            boxShadow:
              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"
          }}
        >
          <p>Sir/Madam,</p>

          <p>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PERK (
            Pulmonary Embolism Registry of Kerala )is multicenter registry based
            study on Pulmonary Embolism – clinical features , risk profile
            ,treatment aspects, mortality and morbidity outcomes on 1 yr of
            follow up. This study is planned as a part of a state wide Pulmonary
            Embolism Registry titled Pulmonary Embolism Registry of Kerala (
            PERK) under the aegis of Cardiological Society Of India Kerala
            Chapter. The inclusion criteria includes CT pulmonary angiography
            proven pulmonary embolism{" "}
            <i>
              <u>
                patients with symptoms and signs of less than 1 month duration.
              </u>{" "}
            </i>
            If the clinical condition does not allow for a CTPA , then an
            Echocardiography with signs and symptoms of Pulmonary embolism is
            enough for inclusion in the study. We intend to have a follow up of
            the recruited patients at 1month, 3 months, 6 months and at 1 yr
            which can be a direct one or through telephonic follow up.It is
            preferable to have a direct follow up at the end of 1 yr with an
            Echocardiography. All valuable contributions to this registry will
            be duly acknowledged.
          </p>

          <p>
            <u>
              {" "}
              Few points to note before you click on Add Patient Data form for
              entering patient details{" "}
            </u>
          </p>
          <ul>
            <li>
              Each patient from a institute will be assigned a unique ID as you
              login from your respective Institute.
            </li>
            <li>Please ensure completeness of the data forms</li>
            <li>
              Please consider entering atleast 2 contact numbers of the patient
              for easy follow up
            </li>
            <li>
              The follow up data at 1 month, 3 months , 6 months and 1 year are
              also available online to be filled
            </li>
            <li>Follow up ECHO at 1yr is advisable</li>
            <li>
              For any queries please contact Dr.S.Praveen ( Mob no 9447861828)
            </li>
          </ul>

          <table width="100%">
            <th>Principal Investigator </th>
            <th>Co Investigator </th>
            <tr>
              <td> Dr.S.Praveen </td>
              <td> Dr.Sunitha Viswanathan </td>
            </tr>
            <tr>
              <td> Assistant Professor </td>
              <td> Professor & HOD </td>
            </tr>
            <tr>
              <td> Department of Cardiology </td>
              <td> Department of Cardiology </td>
            </tr>
            <tr>
              <td>Government Medical College,TVM </td>
              <td> Government Medical College,TVM </td>
            </tr>
            <tr>
              <td> Mob : 9447861828 </td>
              <td>Mob : 9447737403 </td>
            </tr>
            <tr>
              <td> Email : praveensatheesan@gmail.com </td>

              <td> Email : sunithaviswan@gmail.com</td>
            </tr>
          </table>
          <br></br>
          <strong> Zone Investigators </strong>
          <table width="100%">
            <th>South Zone</th>
            <th>Central Zone</th>
            <th>North Zone</th>
            <tr>
              <td>Dr.Sivaprasad.K </td>
              <td>Dr.Jabir .A </td>
              <td>Dr.Rajesh .G </td>
            </tr>

            <tr>
              <td>
                Professor & HOD <br />
                Department of Cardiology{" "}
              </td>
              <td>Consultant Cardiologist </td>
              <td>Addl Professor in Cardiology </td>
            </tr>

            <tr>
              <td>MCH Alappuzha </td>
              <td>Lisie Hospital,Ernakulam </td>
              <td>MCH Calicut </td>
            </tr>

            <tr>
              <td>Mob : 9446301189 </td>
              <td>Mob : 9447011773 </td>
              <td>Mob : 9447290609</td>
            </tr>
          </table>
        </div>
      </Col>
    </Row>

    <Row></Row>

    <Row>
      <Col lg="6"></Col>
    </Row>
    {/* Small Stats Blocks */}
  </Container>
);

BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

BlogOverview.defaultProps = {
  smallStats: [
    {
      label: "No of Patients",
      value: "2,390",
      percentage: " ",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    }
    /*
    {
      label: "Pages",
      value: "182",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Comments",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "New Customers",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "Subscribers",
      value: "17,281",
      percentage: "2.4%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [3, 2, 3, 2, 4, 5, 4]
        }
      ]
    }
    */
  ]
};

export default BlogOverview;
