import React, { Component } from "react";
import { Row, Col } from "shards-react";

import "./css/dashbordCard.css";

import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class dashbordCard extends Component {
  constructor(props) {
    super(props);
    this.getBasicSts = this.getBasicSts.bind(this);
    this.BasicCards = this.BasicCards.bind(this);

    this.state = {
      isLoaded: false,
      basic_status: {
        comp_count: 0,
        incomp_count: 0,
        patient_count: 0,
        userRole: "coordinator"
      }
    };
  }

  async getBasicSts() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    let userData = Cookie.get("userData") ? Cookie.get("userData") : null;
    userData = JSON.parse(userData);
    console.log("🚀🚀🚀", userData);

    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/basic`,

      headers: { Authorization: `Bearer ${token}` }
    });
    if (response2.data.statusCode === 200) {
      this.setState({
        basic_status: response2.data.data,
        userRole: userData.role
      });
    }

    console.log(response2.data.data);
    //console.log(response.data.data);
  }

  componentDidMount() {
    this.getBasicSts();
  }

  BasicCards() {
    return (
      <div>
        <Row>
          <Col lg="4" md="6">
            <div className="cards card1">
              <div className="subtitle">Total Patients</div>
              <div className="title">
                {this.state.basic_status.patient_count}
              </div>
            </div>
          </Col>

          <Col lg="4" md="6">
            <div className="cards card1">
              <div className="subtitle">Completed Patients</div>
              <div className="title">{this.state.basic_status.comp_count}</div>
            </div>
          </Col>

          <Col lg="4" md="6">
            <div className="cards card1">
              <div className="subtitle">Incompleted Patients</div>
              <div className="title">
                {this.state.basic_status.incomp_count}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    if (
      this.state.userRole === "super_admin" ||
      this.state.userRole === "admin"
    ) {
      return (
        <>
          <this.BasicCards />
        </>
      );
    } else {
      return <div></div>;
    }
  }
}
